import { AWS_CHUNK_SIZE } from "../constants/common";

const uniqByKeepFirst = (a, key) => {
  let seen = new Set();
  return a.filter((item) => {
    let k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
};

const uniqByKeepLast = (a, key) => {
  return [...new Map(a.map((x) => [key(x), x])).values()];
};

const isEmpty = (value) => {
  return value === undefined || value === null || (typeof value === "object" && Object.keys(value).length === 0) || (typeof value === "string" && value.trim().length === 0);
};

const filterEmptyValues = (data) =>
  Object.entries(data).reduce((acc, [key, value]) => {
    if (!isEmpty(value)) {
      acc[key] = value;
    }
    return acc;
  }, {});

const getCategoryLabel = (item) => {
  // const languageType = item.languageType || '';
  let type = item.type || "";
  if (type) {
    type = type.replace("CATEGORY_", "").toLowerCase();
    type = type.charAt(0).toUpperCase() + type.slice(1);
  }
  const title = item.title || "";
  return `${title} - ${type}`;
};

const capitalizeFirstLetter = (string) => string?.charAt(0)?.toUpperCase() + string?.slice(1);

const getSubcategoryLabel = (item) => {
  const type = capitalizeFirstLetter(item.type?.replace("CATEGORY_", "")?.toLowerCase()) || "";
  const title = item.title || "";
  return `${title} - ${type}`;
};

const bytesToSize = (bytes, seperator = "") => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (!bytes) return "n/a";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes}${seperator}${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)}${seperator}${sizes[i]}`;
};

const createFileChunks = (file) => {
  let startPointer = 0;
  let endPointer = file.size;
  let chunks = [];
  while (startPointer < endPointer) {
    let newStartPointer = startPointer + AWS_CHUNK_SIZE;
    chunks.push(file.slice(startPointer, newStartPointer));
    startPointer = newStartPointer;
  }
  return chunks;
};

const jsonparse = (item) => {
  try {
    return JSON.parse(item);
  } catch (e) {
    return item;
  }
};

const saveAsFile = (data, fileName, fileType) => {
  const blob = new Blob([data], { type: fileType });
  const elem = window.document.createElement("a");
  elem.href = window.URL.createObjectURL(blob);
  elem.download = fileName;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};

const isVimeoVideoUrl = (url) => url?.includes("vimeo.com");
const isCloudFlareVideoUrl = (url) => url?.includes("cloudflarestream.com");

const download = async (link, name) => {
  try {
    const image = await fetch(link);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const anchor = document.createElement("a");
    anchor.href = imageURL;
    anchor.download = name;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(imageURL);
  } catch (error) {
    console.log(error);
  }
};

const removeExtensionFromFileName = (fileName = "") => fileName.replace(/\.[^/.]+$/, "");

const getFileURL = (url = "") => {
  try {
    const urlData = new URL(url);
    return `${urlData.origin}${urlData.pathname}`;
  } catch (error) {
    return url;
  }
};

const filterMatchedFields = (updatedEntity, fetchedEntity) => {
  const result = {};

  for (const key in updatedEntity) {
    if (fetchedEntity[key] !== updatedEntity[key]) {
      result[key] = updatedEntity[key];
    }
  }

  return result;
};

const sanitizeFileName = (fileName, includeExtension = true) => {
  const lastDotIndex = fileName.lastIndexOf(".");
  let name = lastDotIndex === -1 ? fileName : fileName.slice(0, lastDotIndex);
  const extension = lastDotIndex === -1 ? "" : fileName.slice(lastDotIndex).toLowerCase();

  name = name.replace(/\s+/g, "_");
  name = name.replace(/[^a-zA-Z0-9_-]/g, "");

  if (name.length > 50) {
    name = name.slice(0, 50);
  }
  name = name.toLowerCase();

  const now = new Date();
  const datePart = now.toISOString().slice(0, 10).replace(/-/g, "");
  const timePart = now.toTimeString().slice(0, 8).replace(/:/g, "");
  const dateTime = `${datePart}_${timePart}`;

  const finalName = `${name}_${dateTime}`;

  return includeExtension ? `${finalName}${extension}` : finalName;
};

export {
  download,
  isEmpty,
  filterEmptyValues,
  uniqByKeepFirst,
  uniqByKeepLast,
  getCategoryLabel,
  getSubcategoryLabel,
  bytesToSize,
  createFileChunks,
  jsonparse,
  saveAsFile,
  isCloudFlareVideoUrl,
  isVimeoVideoUrl,
  removeExtensionFromFileName,
  getFileURL,
  filterMatchedFields,
  sanitizeFileName,
};
