import { useState, useContext } from "react";

import { AppContext } from "../contexts";
import { AuthContext } from "contexts/auth.context";

const useForm = (callback) => {
  const [inputs, setInputs] = useState({});

  const submitHandler = (event) => {
    if (event) {
      event.preventDefault();
    }
    callback();
  };

  const inputChangeHandler = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    submitHandler,
    inputChangeHandler,
    inputs,
  };
};

const useAuth = () => useContext(AuthContext);

const useModal = (modalName) => {
  const [isModalOpen, setIsModalOpen] = useState({ [modalName]: false });

  const openModalHandler = (modalName) => () => {
    setIsModalOpen({ [modalName]: true });
  };

  const closeModalHandler = (modalName) => () => {
    setIsModalOpen({ [modalName]: false });
  };

  return {
    isModalOpen,
    openModalHandler,
    closeModalHandler,
  };
};

const useLanguages = () => {
  const { languages = [] } = useContext(AppContext);

  return {
    languages,
    languageSelectOptions: languages.map((language) => ({ label: language.title, value: language.lang })),
  };
};

const useCategories = () => {
  const { categories = {} } = useContext(AppContext);
  return { categories };
};

const useCurrentUser = () => {
  const { currentUser = {} } = useContext(AppContext);
  return { currentUser };
};

export { useForm, useAuth, useModal, useLanguages, useCategories, useCurrentUser };
