import React, { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  const logOutHandler = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  };

  const setLoginToken = (token) => {
    localStorage.setItem("token", token);
    setIsLoggedIn(true);
  };

  return <AuthContext.Provider value={{ isLoggedIn, logOutHandler, setLoginToken }}>{children}</AuthContext.Provider>;
};
